import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Tree from 'react-d3-tree';
import { toast } from 'react-toastify';
import * as ApiService from '../../../services/api';
import './../../../style/genealogy.css';
import { boolean } from 'zod';

enum COLORS {
  'M' = '#2F80ED',
  'F' = '#ED2F51',
  'current' = '#787878'
}

export interface TreeBird {
  name: string;
  gender: Gender;
  dad: TreeBird | null;
  mom: TreeBird | null;
}

export enum Gender {
  F = 'F',
  M = 'M'
}

interface TreeNode {
  name: string;
  root?: boolean;
  gender?: 'M' | 'F';
  children?: TreeNode[];
  _collapsed?: boolean;
  attributes?: Record<string, any>;
}

interface GenealogyProps {
  id: string | number;
  setIsReady?: Dispatch<SetStateAction<boolean>>;
}

export function Genealogy({ id, setIsReady }: GenealogyProps) {
  const [birdTree, setBirdTree] = useState<TreeBird>();

  const getFamilyTree = async () => {
    try {
      if (!id) {
        toast.error(
          'Ocorreu um erro ao tentar buscar a árvore genealógica do pássaro.'
        );
        console.error(
          'O ID é obrigatório para buscar o pássaro para mostrar a árvore genealógica'
        );

        return;
      }

      if (!birdTree) {
        const result = await ApiService.getOne<TreeBird>({
          endpoint: `/family-tree`,
          id
        });

        // console.log(result);

        if (result.isError === false && result?.data) {
          const { data } = result;
          if (data) {
            setBirdTree(data);
            setIsReady && setIsReady(!!data);
          }
          return;
        }

        toast.error(
          result?.data?.error ||
            'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
        );
      }
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    }
  };

  useEffect(() => {
    getFamilyTree();
  }, []);

  return (
    <div id="body-arvore">
      <div>
        {/* Pássaro principal. */}
        <div id="wrapper">
          <span className="label masc">
            {birdTree?.name?.toUpperCase() ?? ''}
          </span>
          {/* Pássaro PAI */}
          <div className="branch">
            <div className="entry">
              <span className="label masc">
                {birdTree?.dad?.name?.toUpperCase() ?? ''}
              </span>
              {/* Avô Paterno */}
              <div className="branch">
                <div className="entry">
                  <span className="label masc">
                    {birdTree?.dad?.dad?.name?.toUpperCase() ?? ''}
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span className="label masc">
                        {birdTree?.dad?.dad?.dad?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.dad?.dad?.dad?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.dad?.dad?.dad?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span className="label fem">
                        {birdTree?.dad?.dad?.mom?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.dad?.dad?.mom?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.dad?.dad?.mom?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entry">
                  <span className="label fem">
                    {birdTree?.dad?.mom?.name?.toUpperCase() ?? ''}
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span className="label masc">
                        {birdTree?.dad?.mom?.dad?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.dad?.mom?.dad?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.dad?.mom?.dad?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span className="label fem">
                        {birdTree?.dad?.mom?.dad?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.dad?.mom?.dad?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.dad?.mom?.dad?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pássaro MÃE */}
            <div className="entry">
              <span className="label fem">
                {birdTree?.mom?.name?.toUpperCase() ?? ''}
              </span>
              <div className="branch">
                <div className="entry">
                  <span className="label masc">
                    {birdTree?.mom?.dad?.name?.toUpperCase() ?? ''}
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span className="label masc">
                        {birdTree?.mom?.dad?.dad?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.mom?.dad?.dad?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.mom?.dad?.dad?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span className="label fem">
                        {birdTree?.mom?.dad?.mom?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.mom?.dad?.mom?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.mom?.dad?.mom?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entry">
                  <span className="label fem">
                    {birdTree?.mom?.mom?.name?.toUpperCase() ?? ''}
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span className="label masc">
                        {birdTree?.mom?.mom?.dad?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.mom?.mom?.dad?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.mom?.mom?.dad?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span className="label fem">
                        {birdTree?.mom?.mom?.mom?.name?.toUpperCase() ?? ''}
                      </span>
                      <div className="branch">
                        <div className="entry">
                          <span className="label masc">
                            {birdTree?.mom?.mom?.mom?.dad?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                        <div className="entry">
                          <span className="label fem">
                            {birdTree?.mom?.mom?.mom?.mom?.name?.toUpperCase() ??
                              ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
