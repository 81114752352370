/* eslint-disable react-hooks/exhaustive-deps */
import { z } from 'zod';
import { useEffect, useState } from 'react';
import * as ApiService from '../../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Str from '../../../helpers/Str';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import BirdParents from '../../../dto/Bird/BirdParents';

import Modal from '../../../components/modal/Modal';
import useModal from '../../../hooks/useModal';
import { FamilyTree } from './FamilyTree';
import { StatusDTO } from '../../../dto/Bird/StatusDTO';

const formSchema = z.object({
  name: z.string().min(1, 'O nome é obrigatório.'),
  gender: z.string().min(1, 'O sexo do pássaro é obrigatório.'),
  statusId: z.number().min(1, 'O status é obrigatório.'),
  // birthDate: z.string().min(1, 'A data de nascimento é obrigatória.')
  birthDate: z
    .string()
    .min(1, 'A data de nascimento é obrigatória')
    .refine((date) => {
      const currentDate = date?.split('-')?.reverse()?.join('/') || '';
      return /(\d{2})\/(\d{2})\/(\d{4})/.test(currentDate);
    }, 'Formato de data inválido! \nFormato de exemplo: 07/11/2019')
});

enum Gender {
  M = 'M',
  F = 'F'
}

interface FormProps {
  formName: string;
  dto?: BirdDTO;
}

const BirdForm = ({ formName, dto }: FormProps) => {
  const IS_EDIT = !!dto;
  const REDIRECT_PATH = '/admin/passaros';
  const API_ENDPOINT = '/bird';
  const navigate = useNavigate();

  /* console.log({
    IS_EDIT,
    dto
  }); */

  const { modalProps, open, close } = useModal();

  const [birdId, setBirdId] = useState(dto?.id || '');
  const [birdName, setBirdName] = useState(dto?.name || '');
  const [scientificName, setScientificName] = useState(
    dto?.scientificName || ''
  );

  const [birdType, setBirdType] = useState(dto?.type ?? '');
  const [ringNumber, setRingNumber] = useState(dto?.ringNumber ?? '');
  const [birthDate, setBirthDate] = useState(
    dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''
  );
  const [gender, setGender] = useState<string>(dto?.gender ?? '');
  const [isDateBar, setIsDateBar] = useState<boolean>(false);
  const [dadId, setDadId] = useState(dto?.dadId?.toString() ?? '');
  const [momId, setMomId] = useState(dto?.momId?.toString() ?? '');
  const [statusId, setStatusId] = useState(dto?.statusId ?? 0);
  const [obs, setObs] = useState(dto?.obs ?? '');
  const [breedPlaceId, setBreedPlaceId] = useState(dto?.breedPlaceId ?? '');

  const [dtos, setDtos] = useState<BirdDTO[]>([]);
  const [parents, setParents] = useState<BirdParents>();
  const [statusList, setStatusList] = useState<StatusDTO[]>();

  // const [dadsOptions, setDadsOptions] = useState('');
  // const [momsOptions, setMomsOptions] = useState('');

  const [fieldErrors, setFieldErrors] = useState<{
    name?: string;
    gender?: string;
    statusId?: string;
    birthDate?: string;
  }>({});
  const [hasError, setHasError] = useState(false);

  const fetchParentOptions = async () => {
    if (!dtos?.length) {
      try {
        console.log('pais e mães');

        const result = await ApiService.listAll<BirdDTO>({
          endpoint: '/bird'
        });
        console.log(result);

        if (result.isError === false && result.data) {
          setDtos(
            result.data?.filter(
              (bird) => bird.id?.toString() !== birdId?.toString()
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    console.log('useEffect DTOs paretns');

    const parentsToSelect = {
      dads:
        dtos
          ?.filter((bird: BirdDTO) => bird?.gender === Gender['M'])
          ?.map((bird) => ({ id: bird?.id, name: bird?.name })) ?? [],
      moms:
        dtos
          ?.filter((bird: BirdDTO) => bird?.gender === Gender['F'])
          ?.map((bird) => ({ id: bird?.id, name: bird?.name })) ?? []
    };

    setParents(parentsToSelect);
  }, [dtos]);

  const fetchStatusToSelect = async () => {
    try {
      if (!statusList?.length) {
        const result = await ApiService.listAll<StatusDTO>({
          endpoint: '/statuses'
        });

        if (result.isError === false && result.data) {
          setStatusList(result.data ?? []);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!handleErrors()) {
      sendData();
    }
  };

  const getFormData = () => {
    const payload: Omit<BirdDTO, 'id'> = {
      name: birdName,
      scientificName,
      type: birdType,
      ringNumber,
      birthDate: birthDate?.split('/').reverse().join('-') ?? '',
      gender: typeof gender == 'string' && gender?.length ? gender : '',
      dadId: parseInt(dadId),
      momId: parseInt(momId),
      statusId: statusId,
      obs
      // breedPlaceId: parseInt(breedPlaceId),
    };

    return payload;
  };

  const handleErrors = () => {
    const validationResult = formSchema.safeParse(getFormData());

    // @ts-ignore
    const errors = validationResult.error?.flatten()?.fieldErrors || {};

    /* setFieldErrors((prevState) => ({
      ...prevState,
      name: errors?.['name']?.[0] || ''
    }));

    setFieldErrors((prevState) => ({
      ...prevState,
      gender: errors?.['gender']?.[0] || ''
    }));
    setFieldErrors((prevState) => ({
      ...prevState,
      birthDate: errors?.['birthDate']?.[0] || ''
    })); */
    setFieldErrors((prevState) => ({
      ...prevState,
      name: errors?.['name']?.[0] || '',
      gender: errors?.['gender']?.[0] || '',
      statusId: errors?.['statusId']?.[0] || '',
      birthDate: errors?.['birthDate']?.[0] || ''
    }));

    // Validando a condicional de erros.
    // setHasError(Object.values(fieldErrors).some((error) => error?.length));

    // console.log(fieldErrors);

    return errors?.name?.[0] ||
      errors?.gender?.[0] ||
      errors?.birthDate?.[0] ||
      errors?.statusId?.[0]
      ? true
      : false;
  };

  /* const hasError = (): boolean => {
    let hasError: boolean = false;

    if (!birdName.length) {
      hasError = true;
      toast.error('O nome é obrigatório.');
    }

    if (!gender.length) {
      hasError = true;
      toast.error('O campo "sexo" não pode ficar vazio.');
    }

    if (birthDate?.length && !Str.validDate(birthDate)) {
      hasError = true;
      toast.error('A data não foi preenchida corretamente.');
    }

    return hasError;
  }; */

  const sendData = async () => {
    // if (hasError) return;

    const formData = getFormData();

    // TODO: vazer um validador com ZOD.

    try {
      const result = IS_EDIT
        ? await ApiService.update({
            endpoint: API_ENDPOINT,
            formData,
            id: birdId
          })
        : await ApiService.create({
            endpoint: API_ENDPOINT,
            formData
          });

      if (result?.data?.success && result?.data?.message?.length) {
        toast.success(result.data.message || 'Dados enviados com sucesso.');
        navigate(REDIRECT_PATH);
        return;
      }

      toast.error(
        result?.data?.error ||
          'Ocorreu um erro ao tentar cadastrar o pássaro. Tente novamente em alguns instantes.'
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao tentar cadastrar o pássaro. Tente novamente em alguns instantes.'
      );
      console.log(error);
    }
  };

  useEffect(() => {
    fetchParentOptions();
    fetchStatusToSelect();
  }, []);

  return (
    <>
      <div className="border-b border-gray-900/10 pb-12">
        {/* TODO: mudar estes textos quando estiver editando um pássaro */}
        <div className="flex justify-between">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {IS_EDIT
                ? 'Edição de informações do pássaro'
                : 'Cadastro de novo pássaro.'}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Preencha os campos de acordo com as informações do pássaro.
            </p>
          </div>

          {IS_EDIT && (
            <button
              onClick={() => navigate(`/passaros/${dto.id}/carteirinha`)}
              className="rounded-md bg-indigo-600 px-2 my-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
            >
              Ver documento
            </button>
          )}
        </div>
        {/* {IS_EDIT && (
          <button
            onClick={() => open()}
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
          >
            Árvore genealógica
          </button>
        )} */}

        <form
          name={formName}
          className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
          onSubmit={handleSubmitForm}
        >
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nome
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                value={birdName}
                // required={true}
                autoComplete="given-name"
                className={Str.tw(
                  fieldErrors?.name ? 'ring-red-600' : 'ring-gray-300',
                  'required:placeholder block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                )}
                onChange={(event) => {
                  setBirdName(event.target.value);
                }}
              />
              {fieldErrors?.name && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.name}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nome científico
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                value={scientificName}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setScientificName(event.target.value);
                }}
              />
            </div>
          </div>

          {/* Status do pássaro */}
          <div className="sm:col-span-2">
            <label
              htmlFor="bird-dad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Status
            </label>
            <div className="mt-2">
              <select
                id="status"
                name="status"
                value={statusId}
                autoComplete="status-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-full sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setStatusId(parseInt(event.target.value));
                }}
              >
                <option value={0}>Selecione um status</option>
                {statusList?.map((status) => {
                  return (
                    <option value={status.id} key={status.id}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
              {fieldErrors?.statusId && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.statusId}
                </span>
              )}
            </div>
          </div>

          {/* TODO: finalizar o cadastro da data de nascimento. */}
          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Data de nascimento
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="birth-date"
                id="birth-date"
                value={birthDate}
                placeholder="ex: 07/11/2019"
                className={Str.tw(
                  fieldErrors?.birthDate ? 'ring-red-600' : 'ring-gray-300',
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                )}
                onKeyDown={(event) => {
                  setIsDateBar(event.key === '/');
                }}
                onChange={(event) => {
                  setBirthDate(Str.maskDate(event.target.value, isDateBar));
                  setIsDateBar(false);
                }}
              />
              {fieldErrors?.birthDate && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.birthDate}
                </span>
              )}
            </div>
          </div>

          {/* Pássaro Pai. */}
          <div className="sm:col-span-2">
            <label
              htmlFor="bird-dad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Pássaro Pai
            </label>
            <div className="mt-2">
              <select
                id="bird-dad"
                name="bird-dad"
                value={dadId}
                autoComplete="bird-dad-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-full sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setDadId(event.target.value);
                }}
              >
                <option value={0}>Selecione uma opção</option>
                {parents?.dads.map((bird) => {
                  return (
                    <option value={bird.id} key={bird.id}>
                      {bird.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* Pássaro Mãe. */}
          <div className="sm:col-span-2">
            <label
              htmlFor="country"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Pássaro Mãe
            </label>
            <div className="mt-2">
              <select
                id="country"
                name="country"
                value={momId}
                autoComplete="country-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-full sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setMomId(event.target.value);
                }}
              >
                <option value={0}>Selecione uma opção</option>
                {parents?.moms.map((bird) => {
                  return (
                    <option value={bird.id} key={bird.id}>
                      {bird.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* Sexo. */}
          <div className="sm:col-span-2">
            <label
              htmlFor="country"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Sexo
            </label>
            <div className="mt-2">
              <select
                id="bird-dad"
                name="bird-dad"
                autoComplete="bird-dad-name"
                value={gender}
                className={Str.tw(
                  fieldErrors?.gender ? 'ring-red-600' : 'ring-gray-300',
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-full sm:text-sm sm:leading-6'
                )}
                onChange={(event) => {
                  setGender(event.target.value);
                }}
              >
                <option value={''}>Selecione uma opção</option>
                <option value={Gender['M']}>Macho</option>
                <option value={Gender['F']}>Fêmea</option>
              </select>
              {fieldErrors?.gender && (
                <span className="text-red-600 text-sm">
                  {fieldErrors?.gender}
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Tipo de canto
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                value={birdType}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setBirdType(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nº anilha
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                id="last-name"
                value={ringNumber}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(event) => {
                  setRingNumber(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="about"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Observações
            </label>
            <div className="mt-2">
              <textarea
                id="about"
                name="about"
                rows={4}
                maxLength={1024}
                value={obs}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={''}
                onChange={(event) => {
                  setObs(event.target.value);
                }}
              />
              <span className="text-sm text-gray-400">{`${obs.length}/1024 caracteres`}</span>
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">
              Escreva aqui algumas observações sobre o pássaro.
            </p>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => navigate('/admin/passaros')}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>

      <Modal size="family-tree" title="Árvore genealógica" {...modalProps}>
        <FamilyTree />
      </Modal>
    </>
  );
};

export default BirdForm;
