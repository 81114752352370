import { useNavigate, useParams } from 'react-router-dom';
import { FamilyTree } from './FamilyTree';
import './../../../style/page.css';
import logo from '../../../assets/img/lagrimas-do-sol-logo-impressao.png';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import { toast } from 'react-toastify';
import * as ApiService from '../../../services/api';
import { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Genealogy } from './Genealogy';
import Str from '../../../helpers/Str';

export function PrintDocument() {
  // TODO: chamar o método de imprimir aqui no documento, adicionando a classe flex na div para ela manter os itens horizontais.
  // TODO: limitar as camadas da árvore quando for imprimir para não ficar muito grande. (usabilidade)
  const navigate = useNavigate();
  const { id } = useParams();
  const [dto, setDto] = useState<BirdDTO>();

  // TODO: usar esse state para mudar a view na hora da impressão.
  const [isPrint, setIsPrint] = useState<boolean>(true);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const [treeReady, setTreeReady] = useState<boolean>(false);

  const contentDocument = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    /* onBeforeGetContent: () => {
      setIsPrint(true);
    }, */
    // TODO: passar a classe da impressão corretamente.
    onBeforePrint: () => {
      // setIsPrinting(true);
    },
    content: () => {
      // setIsPrint(true);
      return contentDocument.current;
    },
    onAfterPrint: () => {
      // setIsPrinting(false);
      navigate(`/passaros/${id}/carteirinha`);
    }
  });

  useEffect(() => {
    if (treeReady && dto) {
      handlePrint();
    }
  }, [treeReady, dto]);

  const getDTO = async () => {
    try {
      if (!id) {
        toast.error('Ocorreu um erro ao tentar buscar o pássaro.');
        console.log('O id é obrigatório para buscar o pássaro para edita-lo.');

        return;
      }

      if (!dto) {
        const result = await ApiService.getOne<BirdDTO>({
          endpoint: '/bird',
          id
        });

        if (result.isError === false && result?.data) {
          setDto(result.data);
          return;
        }

        toast.error(
          result?.data?.error ||
            'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
        );
      }
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao buscar o pássaro. Tente novamente em alguns instantes.'
      );
    }
  };

  useEffect(() => {
    getDTO();
  }, []);

  /* useEffect(() => {
    setTimeout(() => {
      // setIsPrint(false);
    }, 50);
  }, []); */

  return (
    <div
      id="print-document"
      ref={contentDocument}
      className={'grid grid-cols-6'}
    >
      {/* Dados da carteirinha. */}
      <div
        className="
          col-span-3
          grid
          border
          border-black
          my-10
          ml-3
          box-border p-3"
      >
        {/* Nome, logo e gênero */}
        <div className="grid grid-cols-6">
          <div className="col-span-3 flex">
            <div>
              <span className="text-5xl font-bold text-left">{dto?.name}</span>
              <br />
              <span className="text-2xl font-bold text-right">
                {(dto?.gender === 'F' && 'Fêmea') ||
                  (dto?.gender === 'M' && 'Macho') ||
                  ''}
              </span>
            </div>
          </div>
          <div className="col-span-3 flex justify-end">
            <img src={logo} className="h-28" alt="logo" height={'1px'} />
          </div>
        </div>

        <div className="grid">
          {/* Anilha grande */}
          <div className="bg-slate-700 h-9 py-1 flex justify-center items-center rounded-full">
            <span className="text-xl text-white font-bold text-center">
              {dto?.ringNumber}
            </span>
          </div>
        </div>
        <div className="">
          {/* Anilha e nascimento */}
          <div className="grid grid-cols-6">
            <div className="col-span-3 flex justify-center items-center">
              <span className="font-bold">Anilha:&nbsp;</span>{' '}
              {dto?.ringNumber ?? ''}
            </div>
            <div className="col-span-3 flex justify-center items-center">
              <span className="font-bold">Nascimento:&nbsp;</span>
              {dto?.birthDate ? Str.convertDate(dto?.birthDate) : ''}
            </div>
          </div>

          {/* Sexo e tipo */}
          <div className="grid grid-cols-6">
            <div className="col-span-3 flex justify-center items-center">
              <span className="font-bold">Sexo:&nbsp;</span>{' '}
              {(dto?.gender === 'F' && 'Fêmea') ||
                (dto?.gender === 'M' && 'Macho') ||
                ''}
            </div>
            <div className="col-span-3 flex justify-center items-center">
              <span className="font-bold">Tipo de canto:&nbsp;</span>
              {dto?.type ?? ''}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6">
          {/* Anilha grande */}
          <div className="col-span-3 bg-slate-700 h-9 py-1 flex justify-center items-center rounded-full mr-2">
            <span className="text-xl text-white font-bold text-center">
              Pai: {dto?.dad?.name}
            </span>
          </div>

          <div className="col-span-3 bg-slate-700 h-9 py-1 flex justify-center items-center rounded-full ml-2">
            <span className="text-xl text-white font-bold text-center">
              Mãe: {dto?.mom?.name}
            </span>
          </div>
        </div>

        {/* Criadouro */}
        <div className="grid grid-cols-6">
          <div className="col-span-2 flex justify-center items-center">
            <span className="font-bold">Criadouro:&nbsp;</span>{' '}
            {dto?.breedPlace?.name ?? ''}
          </div>
          <div className="col-span-2 flex justify-center items-center">
            <span className="font-bold">Responsável:&nbsp;</span>{' '}
            {dto?.breedPlace?.responsible ?? ''}
          </div>
          <div className="col-span-2 flex justify-center items-center">
            <span className="font-bold">CTF:&nbsp;</span>
            {dto?.breedPlace?.ctf ?? ''}
          </div>
        </div>
      </div>
      <div className="col-span-3 border border-black my-10 mr-3 box-border p-1">
        {id && <Genealogy id={id} setIsReady={setTreeReady} />}
      </div>
      {!(treeReady && dto) ? 'Carregando...' : ''}
    </div>
  );
}
