import { useNavigate } from 'react-router-dom';

import * as ApiService from '../../../services/api';
import BirdDTO from '../../../dto/Bird/BirdDTO';
import { useEffect, useState } from 'react';
import Loading from '../../common/Loading';
import { replaceSpecialChars } from '../../../utils/functions';

export function ListBirds() {
  const navigate = useNavigate();

  const [dtos, setDtos] = useState<BirdDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchBirds = async () => {
    if (!dtos?.length) {
      try {
        const timeOutLoading = setTimeout(() => {
          setIsLoading(true);
        }, 1000);
        const result = await ApiService.listAll<BirdDTO>({
          endpoint: '/bird'
        });

        if (result.isError === false && result.data) {
          setDtos(result.data);
        }
        clearTimeout(timeOutLoading);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchBirds();
  }, []);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-0">
      <div className="flex items-center justify-between flex-column flex-wrap md:flex-row space-y-4 md:space-y-0 pb-8 bg-white">
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => navigate('/admin/passaros/cadastro')}
        >
          Cadastrar novo
        </button>

        {/* Pesquisa */}
        <div className="relative" style={{}}>
          <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            onChange={(e) => {
              setSearchTerm(
                e?.target?.value
                  ? replaceSpecialChars(e?.target?.value?.toLowerCase())
                  : ''
              );
            }}
            id="table-search-users"
            className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500     :ring-blue-500 :border-blue-500"
            placeholder="Pesquisar"
          />
        </div>
      </div>
      {isLoading ? (
        <span className="flex items-center justify-center h-64">
          <Loading fill="fill-gray-900" />
        </span>
      ) : (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nome
              </th>
              <th scope="col" className="px-6 py-3">
                Sexo
              </th>
              <th scope="col" className="px-6 py-3">
                Anilha IBAMA
              </th>
              <th scope="col" className="px-6 py-3 flex justify-center">
                <div></div>
                <div>Ação</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {dtos
              ?.filter((bird) => {
                // Pesquisa vazia retorna todos.
                if (!searchTerm.length) return true;

                // Pesquisa por macho e fêmea.
                if ('macho'.includes(searchTerm)) {
                  return bird.gender === 'M';
                }
                if ('femea'.includes(searchTerm)) {
                  return bird.gender === 'F';
                }

                // Pesquisa por nome, nome científico e anilha.
                return (
                  (bird?.name &&
                    replaceSpecialChars(bird?.name.toLowerCase())?.includes(
                      searchTerm
                    )) ||
                  (bird?.scientificName &&
                    replaceSpecialChars(
                      bird?.scientificName.toLowerCase()
                    )?.includes(searchTerm)) ||
                  (bird?.ringNumber &&
                    replaceSpecialChars(
                      bird?.ringNumber.toLowerCase()
                    )?.includes(searchTerm))
                );
              })
              ?.map((bird, index) => {
                return (
                  <tr
                    className="bg-white border-b hover:bg-gray-50 :bg-gray-600"
                    key={bird?.id ?? index}
                  >
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap "
                    >
                      <div className="ps-3">
                        <div className="text-base font-semibold">
                          {bird.name}
                        </div>
                        <div className="font-normal text-gray-500">
                          {bird.scientificName}
                        </div>
                      </div>
                    </th>
                    <td className="px-6 py-4">
                      {bird.gender === 'M' && 'Macho'}
                      {bird.gender === 'F' && 'Fêmea'}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">{bird.ringNumber}</div>
                    </td>
                    <td className="px-6 py-3 flex justify-center gap-1">
                      <button
                        onClick={() =>
                          navigate(`/admin/passaros/${bird.id}/editar`)
                        }
                        className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() =>
                          navigate(`/passaros/${bird.id}/carteirinha`)
                        }
                        className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:underline"
                      >
                        Ver documento
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
}
